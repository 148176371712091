::-webkit-scrollbar {
  display: none;
}

.drawer-width .ant-drawer-content-wrapper {
  width: 50% !important;
}

.drawer-width .ant-drawer-body {
  padding: 30px;
}

@media screen and (max-width: 972px) {
  .drawer-width .ant-drawer-content-wrapper {
    width: 80% !important;
  }
}

@media screen and (max-width: 576px) {
  .drawer-width .ant-drawer-content-wrapper {
    width: 100% !important;
  }
}
